<template>
  <section id="partner">
    <div class="customer">
      <div class="cus-content">
        <h1>Customers</h1>
        <p>Our clients range from big banks, real estate companies to small businesses, schools, and startups.</p>
      </div>
      <div class="cus-img">
        <div class="img-item">
          <div class="image1 lienviet_img">
            <img class="item-img pic_big" src="../assets/images/cus_img11.png" alt="" />
          </div>
          <div class="image2 lienviet_img">
            <a href="https://lienvietpostbank.com.vn/" target="_blank">
              <img class="pic_big" src="../assets/images/cus_hover11.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <img class="item-img" src="../assets/images/cus_img2.png" alt="" />
          </div>
          <div class="image2">
            <a href="https://ido.cleverme.io/" target="_blank">
              <img src="../assets/images/cus_hover2.png" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <img class="item-img" src="../assets/images/cus_img3.png" alt="" />
          </div>
          <div class="image2">
            <a href="https://lynkey.com/" target="_blank">
              <img src="../assets/images/cus_hover3.png" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <img class="item-img" src="../assets/images/cus_img4.png" alt="" />
          </div>
          <div class="image2">
            <a href="https://gambaru.io/en" target="_blank">
              <img src="../assets/images/cus_hover4.png" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <img class="item-img" src="../assets/images/cus_img55.png" alt="" />
          </div>
          <div class="image2">
            <a href="https://www.longbiengolf.vn/" target="_blank">
              <img src="../assets/images/cus_hover55.png" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <img class="item-img" src="../assets/images/cus_img6.png" alt="" />
          </div>
          <div class="image2">
            <a href="https://www.facebook.com/VandoughCapalaba" target="_blank">
              <img src="../assets/images/cus_hover6.png" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <img class="item-img" src="../assets/images/cus_img7.png" alt="" />
          </div>
          <div class="image2">
            <a href="https://25fit.net/" target="_blank">
              <img src="../assets/images/cus_hover7.png" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <img class="item-img" src="../assets/images/cus_img8.svg" alt="" />
          </div>
          <div class="image2">
            <a href="https://lienviettech.com.vn/" target="_blank">
              <img src="../assets/images/cus_hover8.svg" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <img class="item-img" src="../assets/images/funtap.png" alt="" />
          </div>
          <div class="image2">
            <a href="https://corp.funtap.vn" target="_blank">
              <img src="../assets/images/funtap-color.png" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <img class="item-img" src="../assets/images/cala-game.png" alt="" />
          </div>
          <div class="image2">
            <a href="https://cala.games/" target="_blank">
              <img src="../assets/images/cala-game-color.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="sl-mb">
        <div>
          <div class="img-item img-item-mobile">
            <a class="pic_wid" href="https://lienvietpostbank.com.vn/" target="_blank">
              <img class="pic_big" src="../assets/images/cus_img11.png" alt="" />
            </a>
          </div>
          <div class="img-item img-item-mobile">
            <a href="https://ido.cleverme.io/" target="_blank"><img src="../assets/images/cus_img2.png" alt="" /></a>
          </div>
          <div class="img-item img-item-mobile">
            <a href="https://lynkey.com/" target="_blank"><img src="../assets/images/cus_img3.png" alt="" /></a>
          </div>
          <div class="img-item img-item-mobile">
            <a href="https://gambaru.io/en" target="_blank"><img src="../assets/images/cus_img4.png" alt="" /></a>
          </div>
          <div class="img-item img-item-mobile">
            <a href="https://www.longbiengolf.vn/" target="_blank"><img src="../assets/images/cus_img5.png" alt="" /></a>
          </div>
        </div>
        <div>
          <div class="img-item img-item-mobile">
            <a href="https://www.facebook.com/VandoughCapalaba" target="_blank">
              <img src="../assets/images/cus_img6.png" alt="" />
            </a>
          </div>
          <div class="img-item img-item-mobile">
            <a href="https://25fit.net/" target="_blank"><img class="pic-small img-small" src="../assets/images/cus_img7.png" alt="" /></a>
          </div>
          <div class="img-item img-item-mobile">
            <a href="https://lienviettech.com.vn/" target="_blank"><img src="../assets/images/cus_img88.png" alt="" /></a>
          </div>
          <div class="img-item img-item-mobile">
            <a href="https://corp.funtap.vn" target="_blank"><img src="../assets/images/funtap.png" alt="" /></a>
          </div>
          <div class="img-item img-item-mobile">
            <a href="https://cala.games/" target="_blank"><img src="../assets/images/cala-game.png" alt="" /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {}
    }
  }
</script>

<style scoped>
  .sl-mb {
    display: none;
  }
  .customer {
    width: 100%;
    padding-top: 110px;
    top: 30rem;
  }

  .cus-content {
    text-align: center;
  }

  .cus-content h1 {
    margin: auto;
    font-size: 1.6rem;
    line-height: 1.92rem;
    font-style: normal;
    letter-spacing: 5%;
    color: #999999;
    font-weight: 400;
    font-weight: 400;
    padding-bottom: 5px;
  }

  .cus-content p {
    font-size: 36px;
    font-weight: 600;
    line-height: 4.773rem;
    color: #27282b;
    max-width: 972px;
    width: 100%;
    margin: 0 auto;
  }

  .cus-img {
    margin: 48px auto auto;
    margin-bottom: 110px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1300px;
  }

  .cus-img .img-item {
    height: 170px;
    flex-basis: 20%;
    justify-content: center;
    position: relative;
    /* border: 0.3px solid rgb(238, 227, 227); */
    display: flex;
    align-items: center;
    cursor: pointer;
    object-fit: cover;
  }

  .img-item .pic_big {
    width: 230px !important;
  }

  .img-item .lienviet_img {
    margin: 0px !important;
  }

  .cus-img .img-item img {
    width: 85%;
  }

  .cus-img .img-item .image1 {
    margin-left: 40px;
  }

  .cus-img .img-item:hover .image2 {
    margin-left: 40px;
  }

  .cus-img .img-item:hover .image1 {
    display: none;
  }

  .cus-img .img-item:hover .image2 {
    display: block;
  }

  .cus-img .img-item .image2 {
    display: none;
    /* opacity: 1; */
  }

  .cus-img .img-item .item-img:hover img {
    width: 94%;
    cursor: pointer;
  }
  @media screen and (max-width: 739px) {
    .img-item-mobile a {
      width: 123px;
    }
    .customer {
      padding-top: 60px !important;
    }
    .cus-img {
      display: none;
    }

    .sl-mb {
      display: block;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .cus-content {
      text-align: inherit;
      position: relative;
      left: 2rem;
      padding-right: 2rem;
    }
    .img-item {
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .img-item-mobile .img-small {
      padding: 20px !important;
    }
    .img-item .pic_big {
      height: 41px !important;
      display: flex;
    }

    .pic_wid {
      width: 150px !important;
    }
  }

  @media (min-width: 740px) and (max-width: 1024px) {
    .img-item {
      height: 120px !important;
    }

    .img-item .item-img {
      padding: 10px 15px;
      object-fit: contain;
    }
  }
</style>
