<template>
  <section id="services">
    <div class="services">
      <div class="container">
        <div class="row">
          <div class="services_img hidden-mobile">
            <!-- <div class="media_img current_img">
              <img
                src="https://luna1.co/homepage-features-carousel_EN_project-overview@2x.png?!"
                alt=""
              />
            </div> -->
            <div v-if="bien1">
              <div class="icon">
                <img class="" src="../assets/images/group1.svg" alt="" />
              </div>
            </div>
            <div v-if="bien2">
              <div class="icon" id="icon1">
                <img class="" src="../assets/images/group2.svg" alt="" />
              </div>
            </div>
            <div v-if="bien3">
              <div class="icon">
                <img class="" src="../assets/images/group3.svg" alt="" />
              </div>
            </div>
            <div v-if="bien4">
              <div class="icon">
                <img class="" src="../assets/images/group4.svg" alt="" />
              </div>
            </div>
          </div>

          <div class="services_product">
            <p>Services</p>
            <div class="title">One-time outsource or long-term part of your team? We do both.</div>
            <!-- <span></span> -->
            <div class="services_active">
              <el-collapse v-model="activeNames" accordion @change="handleChange">
                <el-collapse-item title="Blockchain" name="1">
                  <div class="services_product-des">
                    We can build the whole blockchain ecosystem: core infrastructure (blockchain network, cross-chain bridge), smart contract (cryptocurrency, NFT, asset
                    tokenization), dApp (crypto wallet, DeFi), crypto exchange (CEX, DEX), and NFT marketplace.
                    <div class="proccess-bar" v-bind:style="{ width: widthProcess + '%' }"></div>
                  </div>
                  <div v-if="bien1">
                    <div class="icon hidden-pc">
                      <img style="padding-top: 20px" class="" src="../assets/images/group1.svg" alt="" />
                    </div>
                  </div>
                </el-collapse-item>
                <el-collapse-item title="CRM" name="2">
                  <div class="services_product-des">
                    We provide cost-efficient CRM solutions to improve how you interact and do business with your customers. Our in-house enterprise product, BeWork, offers
                    user-friendly CRM features found in much more expensive CRMs (Salesforce, Hubspot, Base).
                    <div class="proccess-bar" v-bind:style="{ width: widthProcess + '%' }"></div>
                  </div>
                  <div v-if="bien2">
                    <div class="icon hidden-pc" id="icon1">
                      <img style="padding-top: 20px" class="" src="../assets/images/group2.svg" alt="" />
                    </div>
                  </div>
                </el-collapse-item>
                <el-collapse-item title="Workplace" name="3">
                  <div class="services_product-des">
                    We provide workplace management solutions to improve how you manage tasks, track workflows, and communicate with your employees, all digitally and
                    automatically. Wiki sharing and Facebook-like internal communication will help employees work more efficiently and engagingly.
                    <div class="proccess-bar" v-bind:style="{ width: widthProcess + '%' }"></div>
                  </div>
                  <div v-if="bien3">
                    <div class="icon hidden-pc">
                      <img style="padding-top: 20px" class="" src="../assets/images/group3.svg" alt="" />
                    </div>
                  </div>
                </el-collapse-item>
                <el-collapse-item title="Custom Software" name="4">
                  <div class="services_product-des">
                    Tell us what you want and our 50+ developers will make it happen. Broadly and simply, we can build anything, whether it is a user-facing mobile app or
                    enterprise back-office application.
                    <div class="proccess-bar" v-bind:style="{ width: widthProcess + '%' }"></div>
                  </div>
                  <div v-if="bien4">
                    <div class="icon hidden-pc">
                      <img style="padding-top: 20px" class="" src="../assets/images/group4.svg" alt="" />
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>
      <div class="assets">
        <div class="assets-content">
          <div class="wrap-slick" style="padding-left: 20px">
            <VueSlickCarousel v-bind="settings" ref="carousel">
              <div class="slide-item slide_align po-relative">
                <img class="image-banner" src="../assets/images/meta.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/wallet.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/be.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/solid.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/golang2.png" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/spring.svg" alt="" />
              </div>
              <div class="slide-item slide_align po-relative">
                <img class="image-banner" src="../assets/images/rabbit2.png" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/kafka2.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/redis.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/vuejs.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/ts.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/electron.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/flutter2.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/swift2.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/kotlin.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner img_small" src="../assets/images/languagec2.png" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/pythonnn.svg" alt="" />
              </div>
              <div class="slide-item po-relative kuber_custom">
                <img class="image-banner" src="../assets/images/kubernet.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner img_small" src="../assets/images/aws.png" alt="" />
              </div>
              <div class="slide-item po-relative s">
                <img class="image-banner img_small" src="../assets/images/docker2.png" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/jenkin2.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/firebase.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/socket.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/mysql.png" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/or.svg" alt="" />
              </div>
              <div class="slide-item po-relative">
                <img class="image-banner" src="../assets/images/mongo.png" alt="" />
              </div>
            </VueSlickCarousel>

            <div class="blog-slider__pagination"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    components: {},
    mounted() {
      // setTimeout(function () {
      // }, 3000);
      this.handleChangeProcess()
    },
    data() {
      return {
        index: 0,
        image: false,
        bien1: true,
        bien2: false,
        bien3: false,
        bien4: false,
        widthProcess: 0,
        activeNames: '1',
        dialogTableVisible: false,
        dialogTableVisibleVanDon: false,
        settings: {
          dotsClass: 'slick-dots custom-dot-class',
          //centerMode: true,
          edgeFriction: 0.35,
          dots: false,
          slidesToShow: 6,
          slidesToScroll: 26,
          initialSlide: 0,
          // swipe: true,
          arrows: false,
          prevArrow: true,
          focusOnSelect: false,
          infinite: true,
          autoplay: true,
          pauseonHover: false,
          autoplayspeed: 30000,
          speed: 30000,
          // autoplaySpeed: 1,
          cssEase: 'linear',
          swipeToSlide: true,
          isActive: true,
          responsive: [
            {
              breakpoint: 1441,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 10
                // centerMode: true,
                // centerPadding: "20px",
              }
            },
            {
              breakpoint: 1260,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 10
                // centerMode: true,
                // centerPadding: "20px",
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 10
                // centerMode: true,
                // centerPadding: "20px",
              }
            },
            {
              breakpoint: 739,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 10
                // centerMode: true,
                // centerPadding: "20px",
              }
            },
            {
              breakpoint: 568,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 10
                // centerMode: true,
                // centerPadding: "20px",
              }
            }
          ]
        }
      }
    },
    methods: {
      handleChange(val) {
        if (val == 1) {
          this.bien1 = true
          this.bien2 = false
          this.bien3 = false
          this.bien4 = false
          this.widthProcess = 0
        }
        if (val == 2) {
          this.bien2 = true
          this.bien1 = false
          this.bien3 = false
          this.bien4 = false
          this.widthProcess = 0
        }
        if (val == 3) {
          this.bien3 = true
          this.bien1 = false
          this.bien2 = false
          this.bien4 = false
          this.widthProcess = 0
        }
        if (val == 4) {
          this.bien4 = true
          this.bien1 = false
          this.bien2 = false
          this.bien3 = false
          this.widthProcess = 0
        }
      },
      handleChangeProcess() {
        setInterval(() => {
          if (this.widthProcess >= 100) {
            this.widthProcess = 0
            let active = parseInt(this.activeNames)

            if (active === 4) {
              active = 1
            } else {
              active++
            }
            this.handleChange(active)
            this.activeNames = active.toString()
          } else {
            this.widthProcess += 0.6
          }
        }, 100)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .services {
    width: 100%;
    overflow: hidden;
    .hidden-mobile {
      justify-content: space-around;
      align-items: center;
    }
    .proccess-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 4px;
      border-radius: 3px 3px 0px 0px;
      background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
      transition: 0.08s linear;
    }
    .hidden-pc {
      display: none;
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap-reverse;

      .services_img {
        height: 72.6rem;
        margin-top: 112px;
        position: relative;
        max-width: 585px;
        width: 100%;
        @keyframes w {
          0% {
            opacity: 0;
            transform: translateY(-10px);
          }

          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
        .icon {
          opacity: 1;
          animation: w 1s linear;

          .img_1 {
            position: absolute;
            bottom: 0;
            right: 0;
            object-fit: cover;
          }
          .img_2 {
            position: absolute;
            right: 0;
            top: 4.4rem;
            object-fit: cover;
          }
          .img_3 {
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
          }
          .img_4 {
            position: absolute;
            top: 11.1rem;
            left: 86px;
            z-index: 1;
            object-fit: cover;
          }
        }
      }

      .services_product {
        width: 100%;
        padding-top: 15.5rem;
        max-width: 64rem;
        p {
          font-size: 1.6rem;
          font-weight: 400;
          letter-spacing: 0.05em;
          line-height: 19.2px;
          color: #999999;
          padding-bottom: 5px;
        }
        &-des {
          max-width: 60rem;
        }
        .title {
          font-size: 56px;
          line-height: 6.72rem;
          color: #363636;
          font-weight: 600;
          padding-bottom: 3.5rem;
        }

        .services_active {
          border: none;
        }
      }
    }
    .slide-item {
      max-width: 220px;
      width: 100%;
      @media (max-width: 767px) {
        max-width: 150px;
      }
    }
  }
  @media (max-width: 1024px) {
    .services {
      // padding-bottom: 47.9px;

      .row {
        justify-content: center;
        .services_img {
          margin-top: 48px;
        }
      }
    }
  }
  //tablet
  @media (min-width: 740px) and (max-width: 1024px) {
    .services {
      .row {
        padding: 0 2rem 0 2rem;

        .services_product {
          width: 100%;
          padding-top: 6.4rem;
          p {
            font-size: 1.6rem;
            line-height: 1.92rem;
            letter-spacing: 0.05em;
          }
        }

        // .assets {
        //   .assets-content {
        //     .wrap-slick {
        //       .wrap-slide {
        //         .slick-slide {
        //           img {
        //             width: 100%;
        //             // padding-bottom: 16px;
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
      }
      .hidden-mobile {
        display: none;
      }
      .hidden-pc {
        display: block;
      }
    }
  }
  //mobile
  @media (max-width: 739px) {
    .services {
      .row {
        padding: 0 2rem 0 2rem;
        .services_img {
          width: 100%;
          height: 438.49px;
          max-width: 334px;
        }
        .services_product {
          width: 100%;
          padding-top: 6.4rem;
          p {
            font-size: 1.6rem;
            line-height: 1.92rem;
            letter-spacing: 0.05em;
          }
          .title {
            font-size: 3.6rem;
            line-height: 43.2px;
            padding-bottom: 2.4rem;
          }
        }

        // .assets {
        //   .assets-content {
        //     padding-top: 24px;
        //     .wrap-slick {
        //       .wrap-slide {
        //         .slick-slide {
        //           img {
        //             width: 100%;
        //             padding-bottom: 16px;
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
      }
      .hidden-mobile {
        display: none;
      }

      .hidden-pc {
        display: block;
      }
    }
  }
</style>
