<template>
  <div class="wrap-banner">
    <div class="wrap-banner-inner">
      <div class="video-wrp">
        <!-- <video class="bg-vid" autoplay loop muted preload="auto" playsinline>
          <source
            src="https://s3.cloud.cmctelecom.vn/bework-production//videos/2022/2/24/AVATAR_bg-intro_1111834715_1645677241226.mp4"
          />
        </video> -->
        <img src="../assets/images/banner-main.png" class="bg-vid isPc" alt="" />
        <img src="../assets/images/mobile.png" class="bg-vid isMobile" alt="" />
        <!-- <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/NwNeR-3xDjs?controls=0"
          class="aa"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe> -->
      </div>
      <div class="container-content container">
        <div class="banner-content">
          <h1>Quality software development</h1>
          <p>You have the vision for a stunning digital experience. We’re the software design and engineering team that can bring it to life. Trusted.</p>
        </div>
      </div>
    </div>
    <a href="#down" class="scroll-bottom">
      <img src="../assets/images/down.svg" alt="" />
    </a>
  </div>
</template>

<script>
  export default {
    components: {},
    mounted() {
      // var TxtRotate = function (el, toRotate, period) {
      //   this.toRotate = toRotate;
      //   this.el = el;
      //   this.loopNum = 0;
      //   this.period = parseInt(period, 10) || 2000;
      //   this.txt = "";
      //   this.tick();
      //   this.isDeleting = false;
      // };
      // TxtRotate.prototype.tick = function () {
      //   var i = this.loopNum % this.toRotate.length;
      //   var fullTxt = this.toRotate[i];
      //   if (this.isDeleting) {
      //     this.txt = fullTxt.substring(0, this.txt.length - 1);
      //   } else {
      //     this.txt = fullTxt.substring(0, this.txt.length + 1);
      //   }
      //   this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";
      //   var that = this;
      //   var delta = 300 - Math.random() * 100;
      //   if (this.isDeleting) {
      //     delta /= 2;
      //   }
      //   if (!this.isDeleting && this.txt === fullTxt) {
      //     delta = this.period;
      //     this.isDeleting = true;
      //   } else if (this.isDeleting && this.txt === "") {
      //     this.isDeleting = false;
      //     this.loopNum++;
      //     delta = 500;
      //   }
      //   setTimeout(function () {
      //     that.tick();
      //   }, delta);
      // };
      // window.onload = function () {
      //   var elements = document.getElementsByClassName("txt-rotate");
      //   for (var i = 0; i < elements.length; i++) {
      //     var toRotate = elements[i].getAttribute("data-rotate");
      //     var period = elements[i].getAttribute("data-period");
      //     if (toRotate) {
      //       new TxtRotate(elements[i], JSON.parse(toRotate), period);
      //     }
      //   }
      //   // INJECT CSS
      //   var css = document.createElement("style");
      //   css.type = "text/css";
      //   css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #fff }";
      //   document.body.appendChild(css);
      // };
    }
  }
</script>

<style lang="scss" scoped>
  .wrap-banner {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    max-height: 100vh;
    height: 100%;
    .wrap-banner-inner {
      max-height: 100vh;
      height: 100%;
      will-change: auto;
      position: relative;
    }
    .video-wrp {
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      .bg-vid {
        z-index: -1;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        vid {
          transition: 0.25s ease;
          object-fit: cover;
        }
      }
    }
    .isPc {
      display: block;
    }
    .isMobile {
      display: none;
    }
    .container-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      display: flex;
      align-items: start;
      flex-direction: column;
      max-width: 1340px;
      padding: 0 20px;
      .banner-content {
        color: #fff;
        h1 {
          font-size: 6.4rem;
          font-weight: 800;
          color: #fff;
          padding-bottom: 2.4rem;
          line-height: 7.6rem;
          max-width: 567px;
          .txt-rotate {
            font-size: 6.4rem;
            font-weight: 800;
            white-space: nowrap;
            border-right: #fff solid 2px;
          }
        }
        p {
          max-width: 554px;
          width: 100%;
          font-size: 1.8rem;
          font-weight: 300;
          line-height: 2.6rem;
        }
      }
    }
    .scroll-bottom {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      animation: 1s scrollDown infinite;
      transition: all 0.6s linear;
    }
  }
  @keyframes scrollDown {
    from {
      bottom: 39px;
      opacity: 0;
    }
    to {
      opacity: 1;
      bottom: 45px;
    }
  }
  @media (max-width: 739px) {
    .wrap-banner {
      max-width: 739px;
      margin: 0;
      max-height: 100vh;
      .wrap-banner-inner {
        max-height: 90vh;
      }
      .bg-vid {
        visibility: visible;
        // width: 1549px;
        // height: 871px;
        max-height: 92vh;
        height: 100%;
      }
      .container-content {
        h1 {
          flex: 50%;
        }
      }
      .isPc {
        display: none;
      }
      .isMobile {
        display: block;
      }
    }
  }
  @media (max-width: 414px) {
    .wrap-banner {
      max-width: 414px;
      width: 100%;
      position: relative;
      .wrap-banner-inner {
        max-width: 414px;
        width: 100%;
        max-height: 100vh;
        height: 100%;
        .video-wrp {
          width: 100%;
          height: 100%;
          bg-vid {
            object-fit: cover;
            visibility: visible;
            // width: 1549px;
          }
        }
        .container-content {
          .banner-content {
            width: 100%;
            h1 {
              width: 100%;
              font-size: 4rem;
              line-height: 4.7rem;
              .txt-rotate {
                font-size: 4rem;
              }
            }
            p {
              font-size: 1.6rem;
              line-height: 2.4rem;
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>
