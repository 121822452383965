<template>
  <div class="introduce-main">
    <banner />
    <feature />
    <About />
    <Services />
    <Product />
    <Customers />
    <Teammate />
    <Slide />
    <Contact />
  </div>
</template>

<script>
  import Banner from '../components/Banner'
  import Feature from '../components/Feature'
  import About from '../components/AboutUs'
  import Services from '../components/Services'
  import Product from '../components/Product'
  import Customers from '../components/Customer.vue'
  import Teammate from '../components/Teammate.vue'
  import Contact from '../components/ContactUs.vue'
  import Slide from '../components/TeamSlide.vue'

  export default {
    components: {
      Banner,
      Feature,
      About,
      Services,
      Product,
      Customers,
      Teammate,
      Slide,
      Contact
    },
    data() {
      return {}
    },
    methods: {},
    mounted() {
      // var sections = document.querySelectorAll("section");
      // var menuItems = document.querySelectorAll(".menu-items li a");
      // window.addEventListener("scroll", () => {
      //   let current = "";
      //   sections.forEach((section) => {
      //     var sectionTop = section.offsetTop;
      //     if (scrollY >= sectionTop) {
      //       current = section.getAttribute("id");
      //     }
      //   });
      // menuItems.forEach((a) => {
      //   a.classList.remove("active");
      //   if (a.classList.contains(current)) {
      //     a.classList.add("active");
      //   }
      // })
      // });
    }
  }
</script>
<style></style>
