<template>
  <section id="teammate">
    <div class="teammate">
      <div class="teammate-content">
        <div class="title-teammate">
          <div class="title-folder-top">Meet our team</div>
          <div class="title-folder-center">Founders</div>
        </div>
        <FounderMember />
        <div class="title-teammate">
          <div class="title-folder-center style-execu">... and the crew</div>
        </div>
        <CrewTeam />
        <!-- <div class="more-about">
          <span>More about us</span>
          <router-link :to="{ name: 'About' }" class="menu-link-pc">
            <img
              style="cursor: pointer"
              class="icon-click-detail"
              src="../assets/images/weview.svg"
              alt=""
            />
          </router-link>
        </div> -->
      </div>
    </div>
  </section>
</template>
<script>
  import FounderMember from './FounderMember.vue'
  import CrewTeam from './CrewTeam'

  export default {
    components: { FounderMember, CrewTeam },
    data() {
      return {}
    }
  }
</script>
<style lang="scss" scoped>
  .teammate {
    background: #fafafa;
    background-repeat: no-repeat;
    background-size: cover;
    .teammate-content {
      padding: 110px 0 100px 0;
    }
    .more-about {
      background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 45px;
      display: flex;
      font-weight: 600;
      flex-direction: column;
      span {
      }
      img {
        transform: translateY(2px);
      }
    }
    .title-folder-top {
      font-size: 1.6rem;
      line-height: 1.9rem;
      font-weight: 400;
      color: #999999;
    }
    .title-folder-center {
      font-size: 4rem;
      line-height: 4.8rem;
      font-weight: 600;
      margin-top: 5px;
      color: #27282b;
    }
    .style-execu {
      margin-top: 110px;
      margin-bottom: 48px;
    }
    .title-teammate {
      max-width: 700px;
      margin: 0 auto;
      margin-bottom: 48px;
      text-align: center;
    }

    // .slick-slide img {
    //   display: unset !important;
    // }

    // tablet & mobile
    @media (max-width: 1024px) {
      .title-teammate {
        text-align: center;
        .title-folder-top {
          justify-content: center;
        }
        .title-folder-center {
          font-size: 2.4rem;
          line-height: 3.8rem;
        }
        .title-folder-bottom {
          line-height: 28px;
        }
      }
    }
    // tablet
    @media (min-width: 740px) and (max-width: 1024px) {
      .teammate-content {
        padding: 80px 0;
        .hide-on-tablet {
          display: none;
        }
      }
    }
    // mobile
    @media (max-width: 739px) {
      .style-execu {
        margin-top: 6.4rem;
      }
      .title-teammate {
        .title-folder-center {
          font-size: 2.4rem;
          line-height: 3.8rem;
        }
      }
      .teammate-content {
        padding: 4.8rem 0;
        .hide-on-mobile {
          display: none;
        }

        .title-teammate {
          text-align: center;
          padding: 0px 20px;
          .title-folder-top {
            justify-content: unset;
            span {
              font-size: 16px;
              line-height: 24px;
            }
          }

          .title-folder-bottom {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
</style>
