<template>
  <section id="product">
    <div class="product">
      <!-- dialog -->
      <!-- <el-dialog
        @close="closeGallery"
        class="iframe_vid"
        :visible.sync="dialogTableVisibleGallery"
      >
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/ajAI0kKu6k8?autoplay=1"
          title="YouTube video player"
          frameborder="0"
          autoplay="autoplay"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </el-dialog> -->

      <el-dialog :visible.sync="handleViewDialog">
        <el-carousel class="carousel" width="100%" height="600px" v-if="view1" :interval="5000" arrow="always" :visible.sync="view1">
          <el-carousel-item v-for="(item, i) in items" :key="'A' + i">
            <!-- <a>{{item}}</a> -->
            <img class="carou_img" :src="showImage" alt="" />

            <div class="actions">
              <span class="prev">
                <i @click="prev_img()" class="fas fa-chevron-left"></i>
              </span>
              <span class="next">
                <i @click="next_img()" class="fas fa-chevron-right"></i>
              </span>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="gallery_slide">
          <div class="gallery_bot" v-for="(item, i) in items" :key="'B' + i">
            <img class="img_bot" @click="change_imgGallery(item.id)" :src="item.src" alt="" />
          </div>
        </div>
      </el-dialog>

      <video
        class="pr-bg"
        id="pr-bg"
        autoplay
        loop
        muted
        preload="auto"
        playsinline
        key="https://s3.cloud.cmctelecom.vn/bework-production//videos/2022/2/24/AVATAR_video-product_352869837_1645677509705.mp4"
      >
        <source src="https://s3.cloud.cmctelecom.vn/bework-production//videos/2022/2/24/AVATAR_video-product_352869837_1645677509705.mp4" />
      </video>

      <div class="pr-header">
        <div class="pr-text">
          <h1>Products</h1>
          <p>Optimization on each touch point</p>
        </div>
        <div class="icon-pause">
          <div v-if="this.isActive" class="icon-pause-btn">
            <i @click="pauseVid" class="far fa-pause-circle"></i>
          </div>
          <div class="icon-play" v-else>
            <i @click="playVid" class="far fa-play-circle"></i>
          </div>
        </div>
      </div>

      <div class="slider">
        <swiper class="slide-track" :options="swiperOption" ref="swiper" @click.native="sliderClicked">
          <swiper-slide class="slide" v-for="(item, index) in listProduct" :key="index">
            <div class="overlay" :data-index="index + 1"></div>
            <img :src="item.src" alt="" class="slide-img" :data-index="index + 1" />
            <div class="sl-text" :data-index="index + 1">
              <div class="overlay1" :data-index="index + 1">
                <span class="name-product" :data-index="index + 1">{{ item.name }}</span>
              </div>
            </div>
            <p class="sl-view" :data-index="index + 1">View more</p>
          </swiper-slide>
          <!-- <swiper-slide class="slide">
            <div class="overlay" @click="view('cex')"></div>
            <img
              src="../assets/images/slide7.png"
              alt=""
              class="slide-img"
              @click="view('cex')"
            />
            <div class="sl-text crm" @click="view('cex')">
              <div class="overlay1">
                <span class="name-product">CEX</span>
              </div>
            </div>
            <p class="sl-view" @click="view('cex')">View more</p>
          </swiper-slide> -->
          <!-- <swiper-slide class="slide">
            <div class="overlay" @click="view('dex')"></div>
            <img
              src="../assets/images/slide8.png"
              alt=""
              class="slide-img"
              @click="view('dex')"
            />
            <div class="sl-text crm" @click="view('dex')">
              <div class="overlay1">
                <span class="name-product">DEX</span>
              </div>
            </div>
            <p class="sl-view" @click="view('dex')">View more</p>
          </swiper-slide> -->
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
  import urlVideo from '../assets/videos/video-product.mp4'
  // eslint-disable-next-line no-unused-vars

  import 'swiper/swiper-bundle.css'
  import Vue from 'vue'
  import { Swiper as SwiperClass, Pagination, Autoplay } from 'swiper/swiper.esm'
  import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
  SwiperClass.use([Pagination, Autoplay])
  Vue.use(getAwesomeSwiper(SwiperClass))
  const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

  export default {
    name: 'swiper-example-grab-cursor',
    title: 'Grab cursor',
    components: {
      Swiper,
      SwiperSlide
    },
    data() {
      return {
        showImage: '',
        index: 1,
        activeImg: false,
        swiperOption: {
          slidesPerView: 9,
          centeredSlides: false,
          spaceBetween: 5,
          // autoplay: {
          //   delay: 2500,
          //   disableOnInteraction: false,
          // },
          // grabCursor: false,
          loop: true
          // loopFillGroupWithBlank: true,
          // pagination: {
          //   el: ".swiper-pagination",
          //   clickable: true,
          // },
        },
        listProduct: [
          {
            id: 1,
            name: 'Communication',
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86584_1648637753508.png'
          },
          {
            id: 2,
            name: 'Workplace',
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86568_1648635149982.png'
          },
          {
            id: 3,
            name: 'eLearning',
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86583_1648637513143.png'
          },
          {
            id: 4,
            name: 'CRM',
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86582_1648637143312.png'
          },
          {
            id: 5,
            name: 'Crypto Wallet',
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86571_1648636060743.png'
          },
          {
            id: 6,
            name: 'Marketplace',
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86577_1648636496776.png'
          }
        ],
        communication: [
          {
            id: 1,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86584_1648637753516.png'
          },
          {
            id: 2,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86584_1648637753540.png'
          },
          {
            id: 3,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86584_1648637753586.png'
          },
          {
            id: 4,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86584_1648637753550.png'
          }
        ],
        workplace: [
          {
            id: 1,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86568_1648635150050.png'
          },
          {
            id: 2,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86568_1648635150067.png'
          },
          {
            id: 3,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86568_1648635150074.png'
          },
          {
            id: 4,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86568_1648635150132.png'
          }
        ],
        eLearning: [
          {
            id: 1,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86583_1648637513111.png'
          },
          {
            id: 2,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86583_1648637513139.png'
          },
          {
            id: 3,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86583_1648637513209.png'
          },
          {
            id: 4,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86583_1648637513208.png'
          }
        ],
        crm: [
          {
            id: 1,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86582_1648637143385.png'
          },
          {
            id: 2,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86582_1648637143405.png'
          },
          {
            id: 3,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86582_1648637143389.png'
          },
          {
            id: 4,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86582_1648637143388.png'
          }
        ],
        crypto: [
          {
            id: 1,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86571_1648636060756.png'
          },
          {
            id: 2,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86571_1648636060750.png'
          },
          {
            id: 3,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86571_1648636060814.png'
          },
          {
            id: 4,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86571_1648636060799.png'
          }
        ],
        marketplace: [
          {
            id: 1,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86577_1648636496825.png'
          },
          {
            id: 2,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86577_1648636496830.png'
          },
          {
            id: 3,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86577_1648636496860.png'
          },
          {
            id: 4,
            src: 'https://s3.cloud.cmctelecom.vn/bework-production/task/district/1/2022/3/30/86577_1648636496877.png'
          }
        ],
        cex: [
          {
            id: 1,
            src: 'https://d25tv1xepz39hi.cloudfront.net/2016-01-31/files/1045.jpg'
          },
          {
            id: 2,
            src: 'https://baoninhbinh.org.vn//DATA/ARTICLES/2021/5/17/cuoc-dua-lot-vao-top-100-anh-dep-di-san-van-hoa-va-thien-7edf3.jpg'
          },
          {
            id: 3,
            src: 'https://cdn.pixabay.com/photo/2018/06/02/17/50/forest-3448818_1280.jpg'
          },
          {
            id: 4,
            src: 'https://d25tv1xepz39hi.cloudfront.net/2016-01-31/files/1045.jpg'
          }
        ],
        dex: [
          {
            id: 1,
            src: 'https://cdn.pixabay.com/photo/2018/06/02/17/50/forest-3448818_1280.jpg'
          },
          {
            id: 2,
            src: 'https://baoninhbinh.org.vn//DATA/ARTICLES/2021/5/17/cuoc-dua-lot-vao-top-100-anh-dep-di-san-van-hoa-va-thien-7edf3.jpg'
          },
          {
            id: 3,
            src: 'https://d25tv1xepz39hi.cloudfront.net/2016-01-31/files/1045.jpg'
          },
          {
            id: 4,
            src: 'https://baoninhbinh.org.vn//DATA/ARTICLES/2021/5/17/cuoc-dua-lot-vao-top-100-anh-dep-di-san-van-hoa-va-thien-7edf3.jpg'
          }
        ],
        items: [],
        activeImage: 0,
        handleViewDialog: false,
        urlVideo: urlVideo,
        view1: false,
        vid1: '',
        isActive: true,
        slideIndex: 1
        // dialogTableVisibleGallery: false,
        // dialogTableVisible: false,
        // settings: {
        //   arrows: false,
        //   // dots: true,
        //   infinite: true,
        //   slidesToShow: 4,
        //   // slidesToScroll: 8,
        //   autoplay: true,
        //   speed: 15000,
        //   autoplaySpeed: 1,
        //   cssEase: "linear",
        //   rtl: false,

        //   // touchMove: false,
        //   // initialSlide: 0,
        //   // draggable: true,
        //   // swipeToSlide: false,
        //   // swipe: true,
        //   // pauseOnFocus: false,
        //   // pauseOnDotsHover: true,
        //   // pauseOnHover: true,

        //   responsive: [
        //     {
        //       breakpoint: 414,
        //       settings: {
        //         slidesToShow: 1,
        //         slidesToScroll: 1,
        //         speed: 3200,
        //         infinite: true,
        //         // dots: true,
        //         autoplay: true,
        //         centerMode: true,
        //         autoplaySpeed: 1,
        //       },
        //     },
        //     {
        //       breakpoint: 739,
        //       settings: {
        //         slidesToShow: 2,
        //         slidesToScroll: 1,
        //         speed: 3200,
        //         infinite: true,
        //         // dots: true,
        //         centerMode: true,
        //         autoplay: true,
        //         autoplaySpeed: 1,
        //       },
        //     },
        //     //  {
        //     //   breakpoint: 1190,
        //     //   settings: {
        //     //     slidesToShow: 3,
        //     //     slidesToScroll: 1,
        //     //     speed: 3200,
        //     //     infinite: true,
        //     //     // dots: true,
        //     //     centerMode: true,
        //     //     autoplay: true,
        //     //     autoplaySpeed: 1,
        //     //   },
        //     // },
        //   ],
        // },
      }
    },

    methods: {
      prev_img() {
        if (this.index === 1) {
          this.index = this.items.length
        } else {
          console.log('vao')
          this.index = this.index - 1
        }
        console.log('id', this.index)
        const item = this.items.filter(x => x.id === this.index)
        this.showImage = item[0].src
      },

      next_img() {
        console.log('index', this.index, 'length', this.items.length)
        if (this.index === this.items.length) {
          this.index = 1
        } else {
          this.index = this.index + 1
        }
        console.log('id', this.index)
        const item = this.items.filter(x => x.id === this.index)
        this.showImage = item[0].src
      },

      change_imgGallery(id) {
        const item = this.items.filter(x => x.id === id)
        this.showImage = item[0].src
      },

      pauseVid() {
        this.isActive = false
        this.settings.autoplay = false
        this.vid1.pause()
      },

      playVid() {
        this.isActive = true
        this.settings.autoplay = true
        this.vid1.play()
      },
      view(type) {
        console.log('a')
        this.handleViewDialog = true
        // this.dialogTableVisibleGallery = true;
        if (type == 1) {
          this.items = this.communication
        } else if (type == 2) {
          this.items = this.workplace
        } else if (type == 3) {
          this.items = this.eLearning
        } else if (type == 4) {
          this.items = this.crm
        } else if (type == 5) {
          this.items = this.crypto
        } else if (type == 6) {
          this.items = this.marketplace
        } else if (type == 'cex') {
          this.items = this.cex
        } else if (type == 'dex') {
          this.items = this.dex
        }
        this.showImage = this.items[0].src
        this.view1 = true
      },
      sliderClicked(event) {
        if (
          !event.target.classList.contains('swiper-pagination-bullet') &&
          !event.target.classList.contains('swiper-button-next') &&
          !event.target.classList.contains('swiper-button-prev')
        ) {
          // let item = this.listProduct[this.swiper];
          let index = event.target.getAttribute('data-index')
          this.view(index)
          // console.log("clicked item", item);
          // console.log("this.swiper", this.swiper);
          //this.$router.push();
        }
      }
    },

    mounted() {
      this.vid1 = document.getElementById('pr-bg')

      var iconPause = document.querySelector('.icon-pause')
      var slideTrack = document.querySelector('.slide-track')
      var slide = document.querySelectorAll('.slide')
      var iconClose = document.querySelector('.el-dialog__headerbtn')
      var overley = document.querySelector('.el-dialog__wrapper')
      iconPause.addEventListener('click', function () {
        slideTrack.classList.toggle('stopSlide')
      })
      slide.forEach(oneSlide => {
        oneSlide.addEventListener('click', function () {
          slideTrack.classList.add('stopSlide')
        })
      })
      iconClose.addEventListener('click', function () {
        slideTrack.classList.remove('stopSlide')
      })
      overley.addEventListener('click', function () {
        slideTrack.classList.remove('stopSlide')
      })
    }
  }
</script>

<style lang="scss" scoped>
  .actions .fas {
    position: absolute;
    font-size: 35px;
    color: white;
    z-index: 999;
    cursor: pointer;
  }
  .active {
    transform: scale(1.1);
  }

  .actions .fa-chevron-left {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .actions .fa-chevron-right {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  // .carou_img {
  //   width: 100%;
  //   height: 100%;
  //   object-fit: contain;
  // }

  .gallery_slide {
    // margin: 30px 30px;
    display: flex;
    justify-content: center;
    // align-items: center;
    // background: #808080;
    width: 100%;
    // grid-template-columns: 1fr 1fr 1fr;
    object-fit: cover;
    height: 15vh;
  }

  .gallery_bot {
    margin-top: 10px;
    // border: 1px solid #ccc;
    // object-fit: cover;
    padding: 2px;
    @media (max-width: 430px) {
      margin-top: -20px;
    }
  }
  // .gallery_bot:hover {
  //   background: #ccc;
  //   transform: scale(1.1);
  // }

  .gallery_bot .img_bot {
    // display: flex;
    height: 100%;
    width: 100%;
    cursor: pointer;
    object-fit: contain;
  }

  .product {
    position: relative;
  }
  .name-product {
    display: flex;
    align-items: center;
  }
  .pr-bg {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: brightness(60%);
    /* position: relative; */
  }

  .pr-bg video {
    height: 750px;
  }

  .pr-header {
    bottom: 37rem;
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    color: #fff;

    @media (max-width: 1310px) {
      padding: 0 20px;
    }
    @media (max-width: 1024px) {
      bottom: 262px;
    }
    @media (max-width: 739px) {
      bottom: 262px;
    }
  }
  .pr-text {
  }

  .pr-header h1 {
    font-weight: 400;
    line-height: 1.92rem;
    letter-spacing: 5%;
    color: #fafafa;
    font-size: 1.6rem;
  }

  .pr-header p {
    font-weight: 600;
    font-size: 4rem;
    line-height: 4.8rem;
    max-width: 66.5rem;
    margin-top: 10px;
    color: #fafafa;
    @media (max-width: 739px) {
      margin-top: 0;
    }
  }
  .pr-header .icon-pause {
    font-size: 3rem;
    line-height: 4.8rem;
    margin-top: 29px;
  }

  .pr-header .icon-pause:hover {
    cursor: pointer;
  }

  .slide a {
    color: black;
  }
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 1rem;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: 1s;
    display: flex;
    /* justify-content: space-between; */
    border: none;
    // margin-top: 8px;
  }
  .slide .sl-text {
    position: absolute;
    width: 13rem;
    height: 3.6rem;
    bottom: 1.8rem;
    color: #fff;
    align-items: center;
    display: flex;
    font-weight: 400;
    left: 4rem;
    opacity: 1;
    font-size: 16px;
    .overlay1 {
      width: 100%;
      height: 100%;
      position: absolute;
      left: -24px;
      top: 2px;
      border-radius: 8px;
      cursor: pointer;
      background: rgba(54, 54, 54, 0.8);
      transition: 1s;
      display: flex;
      justify-content: center;
      text-align: center;
      border: none;
    }
  }

  .slide .crm .overlay1 {
    width: 55px !important;
  }

  .slide:hover .overlay {
    opacity: 0.8;
    transition: 0.1s;
    cursor: pointer;
    border: none;
  }

  .sl-view {
    width: 80%;
    position: absolute;
    bottom: 110px;
    left: 50%;
    opacity: 0;
    transform: translateX(-50%);
    transition: 0;
  }
  .slide:hover .sl-view {
    bottom: 40%;
    opacity: 1;
    width: 15rem;
    height: 5rem;
    background: #fff;
    border-radius: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500 !important;
    line-height: 21, 6px;
    cursor: pointer;
  }
  .slider {
    height: 257px;
    margin: auto;
    position: relative;
    display: grid;
    place-items: center;
    overflow: hidden;
    .slide-track {
      display: flex;
      width: calc(426px * 8);
      // max-width: 100vw;
      animation: scrollSlide 20s linear infinite;
      &:hover {
        animation-play-state: paused;
      }
      .slide {
        width: 410px;
        height: 100%;
        display: flex;
        align-items: center;
        perspective: 100px;
        margin: 8px;
        position: relative;
        .slide-img {
          width: 100%;
        }
      }
    }
  }
  .stopSlide {
    animation-play-state: paused !important;
  }
  @keyframes scrollSlide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-370px * 4));
    }
  }

  @media screen and (max-width: 1024px) {
    .overlay {
      display: none;
    }
  }

  /* tablet */
  @media (min-width: 740px) and (max-width: 1024px) {
    .pr-header .icon-pause {
      display: none;
    }
    // .slide:hover .sl-view {
    //   display: none;
    // }
  }

  @media (min-width: 740px) and (max-width: 1441px) {
    .pr-header {
      bottom: 297px;
    }
  }

  @media screen and (max-width: 1300px) {
    .slide .sl-text {
      position: absolute;
      // bottom: 10px;
      // left: 18px;
    }
  }
  /* mobile */
  @media screen and (max-width: 739px) {
    .pr-header h1 {
      font-size: 1.6rem;
      line-height: 1.92rem;
    }

    .pr-header p {
      max-width: 380px;
      font-size: 3.6rem;
    }

    .pr-header .icon-pause {
      display: none;
    }

    .slide .sl-text {
      // left: 8%;
      // bottom: 8%;
      font-size: 1.4rem;
    }

    .slide .sl-text .overlay1 {
      width: 85%;
    }
    .pr-bg {
      height: 443px;
      width: 100v;
      -o-object-fit: cover;
      object-fit: cover;
      visibility: visible;
      // transform: translateX(-239px);
    }

    .pr-header {
      bottom: 220px;
      h1 {
        font-size: 1.6rem;
        line-height: 1.92rem;
      }
      p {
        max-width: 380px;
        font-size: 3.6rem;
      }
      .icon-pause {
        display: none;
      }
    }

    .slider {
      height: 170px;
      .sl-text {
        font-size: 1.4rem;
        .overlay1 {
          width: 85%;
          left: -30px;
          top: 10px;
        }
      }
    }

    //slide css
    .slider {
      height: 170px;
      .slide-track {
        width: calc(268px * 8);

        .slide {
          width: 252px;
          height: 100%;
        }
      }
    }
  }
  @media (min-width: 300px) and (max-width: 356px) {
    .slide img {
      /* object-fit: cover;
    height: 130px !important; */
      background-size: contain !important;
    }
  }
</style>
