<template>
  <section id="whyblockchain">
    <div class="about_us">
      <div class="container">
        <div class="us">
          <div class="about_text">
            <p>Why Blockchain?</p>
            <h3>Blockchain will take your business to the next level</h3>
            <span
              >We specialize in Blockchain, proudly. Our team founders are early entrepreneurs and scientists in this business. We want to apply our know-how, along with the latest
              blockchain advances, to solving interesting real-world problems; that is, making your project succeed.</span
            >
          </div>
          <div class="about_img">
            <img src="../assets/images/groupcoin.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
  .about_us {
    background-color: #faa227;
    width: 100%;
    .us {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      @media (max-width: 1310px) {
        padding: 0 20px;
      }
      .about_text {
        align-items: center;
        padding-top: 26.006rem;
        width: 40%;
        p {
          font-size: 1.6rem;
          font-weight: 400;
          letter-spacing: 0.05em;
          line-height: 19.2px;

          color: #fafafa;
          padding-bottom: 5px;
        }
        h3 {
          font-weight: 600;
          font-size: 5.6rem;
          line-height: 6.72rem;
          padding-bottom: 4.048rem;
          color: #fafafa;
        }
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #fafafa;
        }
      }
      .about_img {
        align-items: center;
        padding-top: 11.333rem;
        padding-bottom: 11.434rem;
        width: 40%;
        position: relative;
        .coin {
          position: absolute;
          bottom: 6%;
          left: 10%;
        }
      }
    }
  }
  // tablet
  @media (min-width: 740px) and (max-width: 1024px) {
    .about_us {
      .us {
        .about_text {
          width: 100%;
          padding-top: 6.4rem;
          p {
          }
          h3 {
            font-size: 3.6rem;
            line-height: 4.32rem;
            padding-bottom: 2.4rem;
            color: #fafafa;
          }
          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #fafafa;
          }
        }
        .about_img {
          padding-top: 4.8rem;
          padding-bottom: 6.377em;
          width: 100%;
          text-align: center;
        }
      }
    }
    //mobile
  }
  @media (max-width: 739px) {
    .about_us {
      .us {
        width: 100%;
        .about_text {
          width: 100%;
          padding-top: 6.4rem;
          h3 {
            font-size: 3.6rem;
            line-height: 4.32rem;
            padding-bottom: 2.4rem;
            color: #fafafa;
          }
          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #fafafa;
          }
        }
        .about_img {
          padding-top: 4.8rem;
          padding-bottom: 6.377em;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
</style>
